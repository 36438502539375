.footer-social a svg {
  fill: white !important;
  zoom: 1.3;
  &:hover {
    opacity: 0.5;
  }
}

.preload {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;

  .loader {
    border: 5px solid #e6e6e6;
    border-radius: 50%;
    border-top: 5px solid #2b43ff;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .features-tiles-item-image {
      height: 70px;
    }
  }
}
